// OTHER COMPONENTS
import { QuestionBipolarSlider } from './components/QuestionBipolarSlider';
import { QuestionBubbles } from './components/QuestionBubbles';
import { QuestionBubblesHorizontal } from './components/QuestionBubblesHorizontal';
import { QuestionDragDrop } from './components/QuestionDragDrop';
import { QuestionFreeText } from './components/QuestionFreeText';
import { QuestionExplanatory } from './components/QuestionExplanatory';
import { QuestionSegments } from './components/QuestionSegments';
import { QuestionSlider } from './components/QuestionSlider';
import { QuestionDragDropBestLeast } from './components/QuestionDragDropBestLeast';
import { QuestionMultipleChoice } from './components/QuestionMultipleChoice';
import { QuestionCsat } from './components/QuestionCsat';

import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';


export const questionBubblesRanges = {
  from0Step1To2: [
    {
      value: 0,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 1,
      labelLight: '2',
      translationKey: 'assessment_selection_items__tend_to_disagree',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 2,
      labelLight: '3',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
  ],
  from0Step1To3: [
    {
      value: 0,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 1,
      labelLight: '2',
      translationKey: 'assessment_selection_items__tend_to_disagree',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 2,
      labelLight: '3',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
    {
      value: 3,
      labelLight: '4',
      translationKey: 'assessment_selection_items__tend_to_agree',
      translationFallback: 'Trifft eher zu',
    },
  ],
  from0Step1To4: [
    {
      value: 0,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 1,
      labelLight: '2',
      translationKey: 'assessment_selection_items__tend_to_disagree',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 2,
      labelLight: '3',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
    {
      value: 3,
      labelLight: '4',
      translationKey: 'assessment_selection_items__tend_to_agree',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 4,
      labelLight: '5',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
  ],
  from1Step1To2: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
  ],
  from1Step1To3: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
  ],
  from1Step1To4: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'assessment_selection_items__strongly_agree',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To5: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'assessment_selection_items__strongly_agree',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To6: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__tend_to_disagree',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'assessment_selection_items__tend_to_agree',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
    {
      value: 6,
      labelLight: '6',
      translationKey: 'assessment_selection_items__strongly_agree',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To7: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__strongly_disagree',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__disagree',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__tend_to_disagree',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'assessment_selection_items__neutral',
      translationFallback: 'Neutral',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'assessment_selection_items__tend_to_agree',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 6,
      labelLight: '6',
      translationKey: 'assessment_selection_items__agree',
      translationFallback: 'Trifft zu',
    },
    {
      value: 7,
      labelLight: '7',
      translationKey: 'assessment_selection_items__strongly_agree',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To8: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'rmp_ass_scale_item1',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'rmp_ass_scale_item2',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'rmp_ass_scale_item3',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'rmp_ass_scale_item4',
      translationFallback: 'Neutral',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'rmp_ass_scale_item5',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 6,
      labelLight: '6',
      translationKey: 'rmp_ass_scale_item6',
      translationFallback: 'Trifft zu',
    },
    {
      value: 7,
      labelLight: '7',
      translationKey: 'rmp_ass_scale_item7',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 8,
      labelLight: '8',
      translationKey: 'rmp_ass_scale_item8',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To9: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'rmp_ass_scale_item1',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'rmp_ass_scale_item2',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'rmp_ass_scale_item3',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'rmp_ass_scale_item4',
      translationFallback: 'Neutral',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'rmp_ass_scale_item5',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 6,
      labelLight: '6',
      translationKey: 'rmp_ass_scale_item6',
      translationFallback: 'Trifft zu',
    },
    {
      value: 7,
      labelLight: '7',
      translationKey: 'rmp_ass_scale_item7',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 8,
      labelLight: '8',
      translationKey: 'rmp_ass_scale_item8',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 9,
      labelLight: '9',
      translationKey: 'rmp_ass_scale_item9',
      translationFallback: 'Trifft voll zu',
    },
  ],
  from1Step1To10: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'rmp_ass_scale_item1',
      translationFallback: 'Trifft überhaupt nicht zu',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'rmp_ass_scale_item2',
      translationFallback: 'Trifft nicht zu',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'rmp_ass_scale_item3',
      translationFallback: 'Trifft eher nicht zu',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'rmp_ass_scale_item4',
      translationFallback: 'Neutral',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'rmp_ass_scale_item5',
      translationFallback: 'Trifft eher zu',
    },
    {
      value: 6,
      labelLight: '6',
      translationKey: 'rmp_ass_scale_item6',
      translationFallback: 'Trifft zu',
    },
    {
      value: 7,
      labelLight: '7',
      translationKey: 'rmp_ass_scale_item7',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 8,
      labelLight: '8',
      translationKey: 'rmp_ass_scale_item8',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 9,
      labelLight: '9',
      translationKey: 'rmp_ass_scale_item9',
      translationFallback: 'Trifft voll zu',
    },
    {
      value: 10,
      labelLight: '10',
      translationKey: 'rmp_ass_scale_item10',
      translationFallback: 'Trifft voll zu',
    },
  ],
};

const competenciesQuestionBubblesRanges = {
  from1Step1To5: [
    {
      value: 1,
      labelLight: '1',
      translationKey: 'assessment_selection_items__basic_awareness',
      translationFallback: 'Grundlegendes Bewusstsein',
    },
    {
      value: 2,
      labelLight: '2',
      translationKey: 'assessment_selection_items__basic_skills',
      translationFallback: 'Basisfähigkeiten',
    },
    {
      value: 3,
      labelLight: '3',
      translationKey: 'assessment_selection_items__medium_skills',
      translationFallback: 'Mittlere Fähigkeiten',
    },
    {
      value: 4,
      labelLight: '4',
      translationKey: 'assessment_selection_items__advances_skills',
      translationFallback: 'Fortgeschrittene Fähigkeiten',
    },
    {
      value: 5,
      labelLight: '5',
      translationKey: 'assessment_selection_items__expert_skills',
      translationFallback: 'Expertenfähigkeiten',
    },
  ],
};

export const ASSESSMENT_NEXT_TYPES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5',
  [ASSESSMENT_TYPES.RMP]: 'rmp',
  [ASSESSMENT_TYPES.NINE_LEVELS]: 'nineLevels',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workPreferences',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keyCompetencies',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipCompetencies',
  [ASSESSMENT_TYPES.BALANCED_YOU]: 'mindfulness',
  [ASSESSMENT_TYPES.PEER_360]: 'peer360',
};

// GLOBAL_CONFIG
export const GLOBAL_CONFIG = {

  // interaction
  clickBlockDuration: 551, // WARNING: decreasing this will cause
  // major issues due to desync of state and
  // animation timing for question rendering
  // which will result in lost answers.
  // Value comprises of
  // pageForwardsInDuration (400) + pageForwardInDelay (150)
  // or pageForwardsOutDuration if its larger

  // modals
  modalHurryDelay: 30 * 1000, // 30 seconds
  // modalHurryDelay: 10 * 1000, // 10 seconds
  inactivityTimeout: undefined,
  // end
  manualEnd: undefined,

  // storage
  // storageValidityWindow:  30 * 1000, // 30 seconds
  storageValidityWindow: 3 * 60 * 1000, // 3 minutes

  validAssessmentTypes: [ // only documentation & used in stories for now
    // Note: could also get from importing config/index.js
    'custom', // QuestionBubbles
    'balancedYou', 'big5', 'potential', 'rmp', // QuestionBubbles
    'workPreferences', // QuestionBipolarSlider
    'keyCompetencies', 'leadershipCompetencies', // QuestionSegments
    'nineLevels', // QuestionSlider & QuestionDragDrop
    'disc', // QuestionDragDropBestLeast,
    'peer360', // QuestionFreeText,
    'exampleMixed', // mixed
  ],
  validQuestionTypes: {
    explanatory: {
      component: QuestionExplanatory,
    },
    'scale-boxes': {
      component: QuestionBubbles,
      validRangeTypes: questionBubblesRanges,
    },
    'scale-boxes-horizontal': {
      component: QuestionBubblesHorizontal,
      validRangeTypes: questionBubblesRanges,
    },
    'competencies-scale-boxes': {
      component: QuestionBubbles,
      validRangeTypes: competenciesQuestionBubblesRanges,
    },
    'bipolar-scale-slider': {
      component: QuestionBipolarSlider,
    },
    'scale-stepped-slider': {
      component: QuestionSegments,
      validRangeTypes: { from1Step1To5: {} },
    },
    'scale-slider': {
      component: QuestionSlider,
    },
    'free-text': {
      component: QuestionFreeText,
    },
    'free-text-boxes': {
      component: QuestionFreeText,
    },
    'multiple-choice-dragdrop': {
      component: QuestionDragDrop,
    },
    'multiple-choice': {
      component: QuestionMultipleChoice,
    },
    'sort-sort': { // 9 levels stage 2
      component: QuestionDragDrop,
    },
    // multiple target drag&drop
    'select-most-least-select-most-least': {
      component: QuestionDragDropBestLeast,
    },
    // single target drag&drop
    'select-most-least-select-most': {
      component: QuestionDragDropBestLeast,
    },
    csat: {
      component: QuestionCsat,
    },
    'invalid-type': { // keep this for logic.tests of validateQuestions
      component: null,
    },
  },
};
