// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './EditCandidateProfilePanel.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import dayjs from 'dayjs';

// OTHER COMPONENTS
import RadioButtonGroup from 'ui/basic/forms/RadioButtonGroup';
import { QuestionJobFamily } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionJobFamily';
import { QuestionIndustry } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionIndustry';
import { QuestionRequirements } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionRequirements';
import { QuestionAspects } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionAspects';
import { QuestionStartDate } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionStartDate';
import { QuestionName } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionName';
import { QuestionLocation } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionLocation';
import { QuestionNationality } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionNationality';
import { QuestionDegree } from 'ui/molecules/DialogSequence/components/profile-completion/QuestionDegree';
import {
  partTimeOption, remoteOption, salaryOption,
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionRequirements/QuestionRequirements';
import stylesMultiSelect from 'ui/basic/forms/MultiSelect/MultiSelect.module.scss';
import {
  cultureAspectOption, opportunitiesAspectOption, salaryAspectOption,
} from 'ui/molecules/DialogSequence/components/profile-completion/QuestionAspects/QuestionAspects';
import {
  Button, DropDownSearchable, InputNext, InputNumber, SidePanel, UploadImage,
} from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';
import { isNumber, isValid } from 'utils/numbers';
import { DATE_FORMAT } from 'utils/configuration/const/languages';

// STORE
import store from 'store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  fetchStaticValues,
  selectCareerLevels,
  selectCountries,
} from 'features/framework/storeNext/staticValuesSlice';
import { selectUser } from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
const Config = {
  NAVIGATION_ITEMS: [
    { id: 'basic', label: 'basic_info' },
    { id: 'experience', label: 'experience' },
    { id: 'requirements', label: 'requirements' },
  ],
};


// COMPONENT: EditCandidateProfilePanel
const EditCandidateProfilePanel = (props) => {
  // PROPS
  const {
    onClose = () => {},
    showConfirmModal = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const me = useSelector(selectUser);

  const [ currentPage, setCurrentPage ] = useState(Config.NAVIGATION_ITEMS[0].id);

  const GENDER_OPTIONS = [
    {
      label: translate('cp_gender_male'),
      value: 'm',
    },
    {
      label: translate('cp_gender_female'),
      value: 'w',
    },
    {
      label: translate('cp_gender_diverse'),
      value: 'd',
    },
  ];

  const countries = useSelector(selectCountries);
  const careerLevelOptions = useSelector(selectCareerLevels);

  const [ staticValuesRequested, setStaticValuesRequested ] = useState(false);
  useEffect(() => {
    if (staticValuesRequested) {
      return;
    }

    setStaticValuesRequested(true);
    dispatch(fetchStaticValues());
  }, [ dispatch, staticValuesRequested ]);

  // USER DATA: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ candidateInfo, setCandidateInfo ] = useState();
  useEffect(() => {
    if (!me || countries.length === 0) {
      return;
    }

    const candidateInfoInternal = {
      firstName: me.firstName,
      lastName: me.lastName,
      gender: me.profileData?.gender || undefined,
      country: me.profileData?.country || undefined,
      city: me.profileData?.city || undefined,
      nationality: me.profileData?.nationality ? me.profileData.nationality[0] : undefined,

      education: me.profileData?.education || undefined,
      currentPosition: me.profileData?.currentPosition || undefined,
      careerLevel: me.profileData?.careerLevel || undefined,
      currentJobFamily: me.profileData?.currentJobFamily || undefined,

      industryExperience: me.profileData?.industryExperience
        ? me.profileData.industryExperience.map((indExperience) => ({
          name: indExperience.industry || indExperience.industry_name,
          custom: !indExperience.industry,
          experience: indExperience.experience,
        })) : [],
    };

    // JOB REQUIREMENTS
    if (me.profileData) {
      const profileDataInternal = me.profileData;
      const requirements = {};
      const selectedOptions = [];

      const isCustom = (optionValue = [
        profileDataInternal.desiredSalary,
        profileDataInternal.currency || 'EUR',
      ], setValue = () => {}) => (
        <div
          className={stylesMultiSelect.custom}
          role='presentation'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <InputNumber
            label={translate('cp_job_requirements_salary_placeholder')}
            value={optionValue[0]}
            onChange={(salary, salaryIsValid) => {
              setValue([ salary, optionValue[1] ], salaryIsValid);
            }}
          />
          <DropDownSearchable
            placeholder={optionValue[1]?.toUpperCase()}
            activeValue={optionValue[1]}
            options={[
              { value: 'eur', label: 'EUR' },
              { value: 'usd', label: 'USD' },
              { value: 'chf', label: 'CHF' },
              { value: 'gbp', label: 'GBP' },
            ]}
            onChange={(currencyOption) => {
              if (currencyOption) {
                setValue([ optionValue[0], currencyOption.value ], isValid(optionValue[0]));
              }
            }}
          />
        </div>
      );

      if (profileDataInternal.remotePreferred) {
        selectedOptions.push({
          ...remoteOption,
          selected: true,
          value: profileDataInternal.remotePreferred,
        });
      }

      if (profileDataInternal.desiredSalary) {
        selectedOptions.push({
          ...salaryOption,
          selected: true,
          value: [ profileDataInternal.desiredSalary, profileDataInternal.currency || 'EUR' ],
          valueIsValid: isValid(profileDataInternal.desiredSalary),
          isCustom,
        });
      }

      if (profileDataInternal.desiredWeeklyHours) {
        selectedOptions.push({
          ...partTimeOption,
          selected: true,
          value: profileDataInternal.desiredWeeklyHours,
          valueIsValid: isValid(profileDataInternal.desiredWeeklyHours),
        });
      }

      if (selectedOptions.length > 0) {
        requirements.selectedOptions = selectedOptions;
      }

      if (profileDataInternal.additionalRequirements) {
        requirements.customRequirements = profileDataInternal.additionalRequirements;
      }

      candidateInfoInternal.requirements = requirements;
    }

    // MOTIVATION
    if (me.profileData && me.profileData.motivation) {
      const selectedOptions = [];
      const customMotivations = [];
      me.profileData.motivation.forEach((m) => {
        if (m === 'culture') {
          selectedOptions.push({
            ...cultureAspectOption,
            selected: true,
          });
        } else if (m === 'salary') {
          selectedOptions.push({
            ...salaryAspectOption,
            selected: true,
          });
        } else if (m === 'opportunities') {
          selectedOptions.push({
            ...opportunitiesAspectOption,
            selected: true,
          });
        } else {
          customMotivations.push(m);
        }
      });

      candidateInfoInternal.motivations = {
        selectedOptions,
        customAspects: customMotivations,
      };
    }

    // START DATE
    if (me.profileData && me.profileData.earliestStart) {
      const { earliestStart } = me.profileData;

      let radioValue;
      let dateValue;
      if (earliestStart === 'now') {
        radioValue = 'now';
      } else if (dayjs(earliestStart, DATE_FORMAT.DE, true).isValid()) {
        radioValue = 'preferredDate';
        dateValue = earliestStart;
      } else if (earliestStart) {
        radioValue = 'noticePeriod';
        dateValue = earliestStart;
      }

      candidateInfoInternal.startDate = { radioValue, dateValue };
    }

    setCandidateInfo(candidateInfoInternal);
  }, [ me, countries, translate ]);

  // HANDLERS
  const [ updateUserProcessing, setUpdateUserProcessing ] = useState();
  const collectData = () => {
    const profileData = {
      gender: candidateInfo.gender,
      country: candidateInfo.country,
      city: candidateInfo.city,
      education: candidateInfo.education,
      nationality: [ candidateInfo.nationality ].filter(Boolean),
      currentPosition: candidateInfo.currentPosition,
      careerLevel: candidateInfo.careerLevel,
      industryExperience: [],
    };

    profileData.currentJobFamily = candidateInfo.currentJobFamily.id || candidateInfo.currentJobFamily.name || '';

    if (candidateInfo.industryExperience && candidateInfo.industryExperience.length > 0) {
      profileData.industryExperience = candidateInfo.industryExperience?.map((indExperience) => ({
        industry: indExperience.name,
        experience: indExperience.experience,
      }));
    }

    if (candidateInfo.requirements?.selectedOptions) {
      const remote = candidateInfo.requirements.selectedOptions.find((selectedOption) => (
        selectedOption.name === 'remote'
      ));
      profileData.remotePreferred = remote ? remote.value : 0;

      const partTime = candidateInfo.requirements.selectedOptions.find((selectedOption) => (
        selectedOption.name === 'partTime'
      ));
      profileData.desiredWeeklyHours = partTime ? Number(partTime.value) : 0;

      const salary = candidateInfo.requirements.selectedOptions.find((selectedOption) => (
        selectedOption.name === 'salarySpecific'
      ));
      if (salary) {
        profileData.desiredSalary = Number(salary.value[0]);
        // eslint-disable-next-line prefer-destructuring
        profileData.currency = salary.value[1];
      } else {
        profileData.desiredSalary = 0;
      }
    }

    profileData.additionalRequirements = candidateInfo?.requirements?.customRequirements;

    if (candidateInfo.motivations) {
      const selectedMotivations = candidateInfo.motivations.selectedOptions || [];
      const customMotivations = candidateInfo.motivations.customAspects || [];

      profileData.motivation = [
        ...selectedMotivations.map((m) => m.name),
        ...customMotivations,
      ];
    }

    if (candidateInfo.startDate) {
      const { radioValue } = candidateInfo.startDate;
      const { dateValue } = candidateInfo.startDate;
      if (radioValue === 'now') {
        profileData.earliestStart = 'now';
      } else {
        profileData.earliestStart = dateValue;
      }
    }

    return {
      firstName: candidateInfo.firstName,
      lastName: candidateInfo.lastName,
      profileData,
    };
  };

  const valuesNotEqual = (value1, value2) => {
    if ((value1 === undefined || value1 === null) && (value2 === undefined || value2 === null)) {
      return false;
    }

    if ((Array.isArray(value1) && value1.length === 0 && (value2 === undefined || value2 === null))
      || (Array.isArray(value2) && value2.length === 0 && (value1 === undefined || value1 === null))
    ) {
      return false;
    }

    return value1 !== value2;
  };

  const isProfileDataChanged = () => {
    const collectedCandidateInfo = collectData();

    const newProfileData = collectedCandidateInfo.profileData;
    const oldProfileData = me.profileData;

    if (valuesNotEqual(collectedCandidateInfo.firstName, me.firstName)
      || valuesNotEqual(collectedCandidateInfo.lastName, me.lastName)) {
      return true;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in newProfileData) {
      if (key === 'industryExperience') {
        if (newProfileData[key].length !== oldProfileData[key].length) {
          return true;
        }
        for (let i = 0; i < newProfileData[key].length; i += 1) {
          const industryExperienceItem = newProfileData[key][i];
          const newIndustry = industryExperienceItem.industry;
          const oldIndustryItem = oldProfileData[key][i];

          if ((!isNumber(newIndustry) && valuesNotEqual(oldIndustryItem.industry_name, newIndustry))
              || (isNumber(newIndustry) && valuesNotEqual(oldIndustryItem.industry, newIndustry))
              || valuesNotEqual(
                industryExperienceItem.experience,
                oldProfileData[key][i].experience,
              )
          ) {
            return true;
          }
        }
      } else if (key === 'currentJobFamily') {
        if (valuesNotEqual(newProfileData[key], oldProfileData[key].id)
          && valuesNotEqual(newProfileData[key], oldProfileData[key].name)
        ) {
          return true;
        }
      } else if ((key === 'motivation' || key === 'additionalRequirements' || key === 'nationality')
        && newProfileData[key] && oldProfileData[key]
      ) {
        if (newProfileData[key].length !== oldProfileData[key].length) {
          return true;
        }
        for (let i = 0; i < newProfileData[key].length; i += 1) {
          const profileDataItem = newProfileData[key][i];
          if (valuesNotEqual(profileDataItem, oldProfileData[key][i])) {
            return true;
          }
        }
      } else if (valuesNotEqual(newProfileData[key], oldProfileData[key])) {
        return true;
      }
    }

    return false;
  };

  const handleClose = () => {
    const hasUpdatedChanges = isProfileDataChanged();

    if (hasUpdatedChanges) {
      showConfirmModal(collectData());
    } else {
      onClose(hasUpdatedChanges);
    }
  };

  const handleUpdateUser = () => {
    setUpdateUserProcessing(true);
    const payload = collectData();

    api.post('core/user/update', payload)
    .then(({ ok, status }) => {
      setUpdateUserProcessing(false);
      if (ok && status === 200) {
        onClose(isProfileDataChanged());
      }
    })
    .catch((error) => {
      console.error(error.message);
      setUpdateUserProcessing(false);
    });
  };

  /**
   * Check whether all required fields have been filled in.
   *
   * @returns {Boolean}
   */
  const isRequiredInfoAvailable = () => {
    const checkCareerLevel = () => {
      const {
        careerLevel,
        currentPosition,
        currentJobFamily,
        industryExperience,
      } = candidateInfo;

      // Career starters don't need to fill in the other job fields.
      // Only check that industry experience fields are ok.
      if (careerLevel === 1) {
        return industryExperience.every(({ experience, name }) => (name && experience))
          || industryExperience.every(({ experience, name }) => (!name && !experience));
      }

      const isValidJobFamily = currentJobFamily.id || currentJobFamily.name;
      const isValidIndustryExperience = industryExperience
      .every(({ name, experience }) => name && experience);

      return isValidJobFamily && currentPosition && isValidIndustryExperience;
    };

    const checkJobRequirements = () => {
      const { selectedOptions = [] } = candidateInfo.requirements;

      return selectedOptions
      .map(({
        name, value, valueIsValid, isInput,
      }) => (name === 'salarySpecific'
        ? (value?.[0] && valueIsValid) // value not empty and valid
        : ((isInput && value && valueIsValid) || (!isInput && value))
      ))
      .every(Boolean);
    };

    const checkStartDate = () => {
      const { radioValue, dateValue } = candidateInfo.startDate;
      if (radioValue === 'now') {
        return true;
      }
      if (radioValue === 'preferredDate') {
        // use DE format for validation (this is how the date is returned)
        const date = dayjs(dateValue, Config.FORMAT_DE, true);
        return date.isValid();
      }
      return Boolean(dateValue);
    };

    return (
      candidateInfo.firstName
      && candidateInfo.lastName
      && candidateInfo.country
      && candidateInfo.education
      && checkCareerLevel()
      && checkJobRequirements()
      && checkStartDate()
    );
  };

  if (!candidateInfo) {
    return null;
  }

  // RENDER: EditCandidateProfilePanel
  return (
    <SidePanel
      size='M'
      closeOnCancel={false}
      onClose={handleClose}
    >
      <div className={styles.editCandidateProfilePanel}>
        { /* HEADER */ }
        <div className={styles.header}>
          { translate('edp_edit_profile_data') }
        </div>

        { /* SUB HEADER */ }
        <div className={styles.subHeader}>
          { Config.NAVIGATION_ITEMS.map((navigationItem) => (
            <div
              key={navigationItem.id}
              role='presentation'
              className={classNames(
                styles.navigationItem,
                { [styles.active]: currentPage === navigationItem.id },
              )}
              onClick={() => setCurrentPage(navigationItem.id)}
            >
              { translate(navigationItem.label) || navigationItem.label }
            </div>
          )) }
        </div>

        { /* CONTENT */ }
        <div className={styles.content}>
          { /* BASIC INFO PAGE */ }
          { currentPage === 'basic' && (
            <>
              <div className={styles.block}>
                <Provider store={store}>
                  <UploadImage
                    image={me.picture}
                    altImageText={me.firstName.substring(0, 1) + me.lastName.substring(0, 1)}
                    text={translate('edp_link_edit_profile_picture')}
                  />
                </Provider>
              </div>

              <div className={styles.block}>
                <QuestionName
                  size='S'
                  answer={{
                    firstName: candidateInfo.firstName,
                    lastName: candidateInfo.lastName,
                  }}
                  onAnswer={({ firstName, lastName }) => setCandidateInfo(
                    (state) => ({ ...state, firstName, lastName }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('rmp_ass_form_gender_label') }</div>
                <RadioButtonGroup
                  name='radio-button-group'
                  selectedValue={candidateInfo.gender}
                  items={GENDER_OPTIONS}
                  deselectable
                  onChange={(gender) => setCandidateInfo((state) => ({ ...state, gender }))}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('residence') }</div>
                <QuestionLocation
                  size='S'
                  answer={{
                    country: candidateInfo.country,
                    city: candidateInfo.city,
                  }}
                  onAnswer={({ city, country }) => setCandidateInfo(
                    (state) => ({ ...state, city, country }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('nationality') }</div>
                <QuestionNationality
                  size='S'
                  answer={{
                    value: candidateInfo.nationality,
                    nationality: candidateInfo.nationality,
                  }}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, nationality: value }),
                  )}
                />
              </div>
            </>
          ) }

          { /* EXPERIENCE PAGE */ }
          { currentPage === 'experience' && (
            <>
              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('highest_school_degree') }</div>
                <QuestionDegree
                  size='S'
                  answer={{
                    value: candidateInfo.education,
                  }}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, education: value }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('position_and_careerlevel') }</div>
                <InputNext
                  size='S'
                  label={translate('current_position')}
                  value={candidateInfo.currentPosition}
                  onChange={(currentPosition) => setCandidateInfo(
                    (state) => ({ ...state, currentPosition }),
                  )}
                />
                <DropDownSearchable
                  size='S'
                  placeholder={translate('please_select_lbl')}
                  filterPlaceholder={translate('search_lbl')}
                  activeValue={me.profileData?.careerLevel || undefined}
                  options={careerLevelOptions}
                  onChange={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, careerLevel: value }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('job_family') }</div>
                <QuestionJobFamily
                  size='S'
                  answer={{
                    useDropDown: !!candidateInfo.currentJobFamily.id,
                    value: candidateInfo.currentJobFamily.id || candidateInfo.currentJobFamily.name,
                  }}
                  onAnswer={({ useDropDown, value }) => setCandidateInfo((state) => ({
                    ...state,
                    currentJobFamily: {
                      id: useDropDown ? value : undefined,
                      name: !useDropDown ? value : undefined,
                    },
                  }))}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('industry_experience') }</div>
                <QuestionIndustry
                  size='S'
                  answer={candidateInfo.industryExperience.length
                    ? candidateInfo.industryExperience
                    : undefined}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, industryExperience: value }),
                  )}
                />
              </div>
            </>
          ) }

          { /* REQUIREMENTS PAGE */ }
          { currentPage === 'requirements' && (
            <>
              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('job_requirements') }</div>
                <QuestionRequirements
                  size='S'
                  answer={{ value: candidateInfo.requirements }}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, requirements: value }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('job_motivation') }</div>
                <QuestionAspects
                  size='S'
                  answer={{ value: candidateInfo.motivations }}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, motivations: value }),
                  )}
                />
              </div>

              <div className={styles.block}>
                <div className='bluTypeLabel'>{ translate('start_termin') }</div>
                <QuestionStartDate
                  size='S'
                  answer={{ value: candidateInfo.startDate }}
                  onAnswer={({ value }) => setCandidateInfo(
                    (state) => ({ ...state, startDate: value }),
                  )}
                />
              </div>
            </>
          ) }
        </div>

        { /* FOOTER */ }
        <div className={styles.footer}>
          <Button
            size='M'
            looks='secondary'
            onClick={handleClose}
          >
            { translate('cancel_lbl') }
          </Button>
          <Button
            size='M'
            disabled={updateUserProcessing || !isRequiredInfoAvailable()}
            onClick={handleUpdateUser}
          >
            { translate('save_lbl') }
          </Button>
        </div>
      </div>

    </SidePanel>
  );
};

export default EditCandidateProfilePanel;
